import React from 'react'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import Header from '../common/template/header'
import Footer from '../common/template/footer'
import Routes from './routes'
import AdSense from 'react-adsense';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCAFkB1_9OqLnHziPC_BIm6Gp7N_nr_0FI",
    authDomain: "textcaseconvert-3679c.firebaseapp.com",
    projectId: "textcaseconvert-3679c",
    storageBucket: "textcaseconvert-3679c.appspot.com",
    messagingSenderId: "109555819996",
    appId: "1:109555819996:web:5f311997f50664ad5cdb19",
    measurementId: "G-JTRDXLBDRD"
};

const app = props => {

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    if (app.name && typeof window !== 'undefined') {
        getAnalytics(app);
    }
    
    return (
        <React.Fragment>
            <Header />
            <main role="main" className="flex-shrink-0">
                <div className="container mt-5">
                    {/* // auto full width responsive ads */}
                    <AdSense.Google
                        client='ca-pub-2605486701851317'
                        slot='4801753310'
                        style={{ display: 'block' }}
                        format='auto'
                        responsive='true'
                        layoutKey='-gw-1+2a-9x+5c'
                    />
                    <Routes />
                </div>
            </main>
            <Footer />
        </React.Fragment>
    )
}

export default app